<template>
  <v-list>
    <v-list v-for="combinedItem in combined" :key="combinedItem.id">
      <ListItemTitleCategory v-if="combinedItem.change" :item="combinedItem" />
      <ItemCombined :combined="combinedItem" @selectCombined="updateCombined" />
    </v-list>
  </v-list>
</template>

<script>
import { combinedStoreActions } from "../../store/modules/combined/constNames";

export default {
  name: "ListCombined",
  props: {
    combined: {
      type: Array,
      required: true,
    },
  },
  components: {
    ItemCombined: () => import("./ListItemCombined.vue"),
    ListItemTitleCategory: () => import("./ListItemTitleCategory.vue"),
  },
  data() {
    return {
      combinedSelected: null,
    };
  },
  methods: {
    updateCombined(combined) {
      if (this.combinedSelected)
        this.$store.dispatch(
          combinedStoreActions.DELETE_COMBINED,
          this.combinedSelected
        );
      this.combinedSelected = combined;
      this.combined.forEach(
        (combinedItemView) =>
          (combinedItemView.selected = combined.id == combinedItemView.id)
      );
      this.$store.dispatch(
        combinedStoreActions.ADD_COMBINED,
        this.combinedSelected
      );
    },
  },
};
</script>
